<template>
  <div ref="el" :class="{ 'bg-primaryGray': gray }">
    <div class="mx-auto max-w-2xl p-4">
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
const el = ref<HTMLElement>()

defineProps<{
  gray?: boolean
}>()

defineExpose({
  el,
})
</script>

<template>
  <Transition
    :css="false"
    @before-enter="
      (el: Element) => {
        gsap.set(el, { height: 0, overflow: 'hidden' })
      }
    "
    @enter="
      (el: Element, done: () => void) => {
        gsap.to(el, { height: 'auto', duration: 0.3, onComplete: () => done() })
      }
    "
    @leave="
      (el: Element, done: () => void) => {
        gsap.to(el, { height: 0, duration: 0.2, onComplete: () => done() })
      }
    "
  >
    <slot />
  </Transition>
</template>

<script setup lang="ts">
const { gsap } = useGsap()
</script>

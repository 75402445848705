import revive_payload_client_EoiqYtp6aJ from "/opt/build/repo/nuxt/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.0.0_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_lh3dldm6vwuyyxmeocbnpeqqti/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_qIsl5NXLzs from "/opt/build/repo/nuxt/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.0.0_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_lh3dldm6vwuyyxmeocbnpeqqti/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_97tIHV86Q5 from "/opt/build/repo/nuxt/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.0.0_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_lh3dldm6vwuyyxmeocbnpeqqti/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_AqfZDWb3o2 from "/opt/build/repo/nuxt/node_modules/.pnpm/nuxt-site-config@2.2.15_magicast@0.3.4_rollup@4.19.1_vite@5.3.5_@types+node@22.0.0_terser@5.3_lk345e5ieiek7numrjg2w2z4ry/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_mfMvN0jpn2 from "/opt/build/repo/nuxt/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.0.0_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_lh3dldm6vwuyyxmeocbnpeqqti/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_qld29Amm44 from "/opt/build/repo/nuxt/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.0.0_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_lh3dldm6vwuyyxmeocbnpeqqti/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_nQ4gmO9Esz from "/opt/build/repo/nuxt/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.0.0_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_lh3dldm6vwuyyxmeocbnpeqqti/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_Erk5fJWTJC from "/opt/build/repo/nuxt/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.0.0_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_lh3dldm6vwuyyxmeocbnpeqqti/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/opt/build/repo/nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_lSjjS5kaal from "/opt/build/repo/nuxt/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.0.0_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_lh3dldm6vwuyyxmeocbnpeqqti/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import titles_xfCD1NaMAa from "/opt/build/repo/nuxt/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.16_h3@1.12.0_magicast@0.3.4_rollup@4.19.1_vite@5.3.5_@types+node@22.0.0__34kkyy6kz7rg2j7yxeu7cmwbgu/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.js";
import defaults_J5VLZFCDOI from "/opt/build/repo/nuxt/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.16_h3@1.12.0_magicast@0.3.4_rollup@4.19.1_vite@5.3.5_@types+node@22.0.0__34kkyy6kz7rg2j7yxeu7cmwbgu/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaults.js";
import siteConfig_2ASXRS4e2t from "/opt/build/repo/nuxt/node_modules/.pnpm/nuxt-seo-experiments@4.0.0_magicast@0.3.4_rollup@4.19.1_vite@5.3.5_@types+node@22.0.0_terser@_w5xjpky45jgcv5sprrqvw3wz7e/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.js";
import inferSeoMetaPlugin_skkhkdQdPP from "/opt/build/repo/nuxt/node_modules/.pnpm/nuxt-seo-experiments@4.0.0_magicast@0.3.4_rollup@4.19.1_vite@5.3.5_@types+node@22.0.0_terser@_w5xjpky45jgcv5sprrqvw3wz7e/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.js";
import plugin_MaQUZudQzB from "/opt/build/repo/nuxt/node_modules/.pnpm/nuxt-bugsnag@7.3.0_@bugsnag+core@7.25.0_magicast@0.3.4_rollup@4.19.1/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import plugin_dPUN3M6pw1 from "/opt/build/repo/nuxt/node_modules/.pnpm/@dargmuesli+nuxt-cookie-control@8.4.6_magicast@0.3.4_rollup@4.19.1_webpack@5.93.0_esbuild@0.21.5_/node_modules/@dargmuesli/nuxt-cookie-control/dist/runtime/plugin.js";
import plugin_client_tmd8HbWK0P from "/opt/build/repo/nuxt/node_modules/.pnpm/nuxt-gtag@2.1.0_magicast@0.3.4_rollup@4.19.1/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import plugin_YNsWh2EaCz from "/opt/build/repo/nuxt/node_modules/.pnpm/@nuxt+icon@1.4.3_magicast@0.3.4_rollup@4.19.1_vite@5.3.5_@types+node@22.0.0_terser@5.31.3__vue@3.4.35_typescript@5.5.4_/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import plugin_Rv61nS9Xfz from "/opt/build/repo/nuxt/node_modules/.pnpm/dayjs-nuxt@2.1.9_magicast@0.3.4_rollup@4.19.1/node_modules/dayjs-nuxt/dist/runtime/plugin.mjs";
export default [
  revive_payload_client_EoiqYtp6aJ,
  unhead_qIsl5NXLzs,
  router_97tIHV86Q5,
  _0_siteConfig_AqfZDWb3o2,
  payload_client_mfMvN0jpn2,
  navigation_repaint_client_qld29Amm44,
  check_outdated_build_client_nQ4gmO9Esz,
  chunk_reload_client_Erk5fJWTJC,
  components_plugin_KR1HBZs4kY,
  prefetch_client_lSjjS5kaal,
  titles_xfCD1NaMAa,
  defaults_J5VLZFCDOI,
  siteConfig_2ASXRS4e2t,
  inferSeoMetaPlugin_skkhkdQdPP,
  plugin_MaQUZudQzB,
  plugin_dPUN3M6pw1,
  plugin_client_tmd8HbWK0P,
  plugin_YNsWh2EaCz,
  plugin_Rv61nS9Xfz
]
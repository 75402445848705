<template>
  <div>
    <button
      ref="target"
      class="flex items-center gap-1 uppercase transition-opacity duration-300"
      :class="[{ 'opacity-60': open }]"
      :aria-expanded="open"
      @click="toggleOpen()"
    >
      <span class="font-bold">{{ title }}</span>
      <Icon name="ic:outline-keyboard-arrow-down" />
    </button>
    <TransitionCollapse v-if="items.length">
      <ul
        v-if="open"
        ref="list"
        class="grid h-0 gap-2 overflow-hidden pl-2 pt-2"
      >
        <li
          v-for="({ menu: item, label }, index) in items"
          :key="`accordion-${item._type}-${index}`"
        >
          <MenuItem :item="item" :label="label" />
        </li>
      </ul>
    </TransitionCollapse>
  </div>
</template>

<script setup lang="ts">
import type { MenuDropdown } from "~/@types/siteSettings"

const props = defineProps<{
  menu: MenuDropdown
}>()

const { menu } = toRefs(props)

const title = computed(() => menu.value.label)
const items = computed(() => menu.value.dropdowns ?? [])

const target = ref<HTMLElement>()
const list = ref<HTMLElement>()

const [open, toggleOpen] = useToggle(false)
</script>

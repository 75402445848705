<template>
  <ul class="flex flex-wrap gap-5 gap-y-4 text-xs font-bold uppercase">
    <template
      v-for="({ title, slug }, index) in footerLinks"
      :key="`menu-${slug.current}-${index}`"
    >
      <li v-if="title">
        <NuxtLink :to="`/page/${slug.current}`" :title="title">
          {{ title }}
        </NuxtLink>
      </li>
    </template>
  </ul>
</template>

<script setup lang="ts">
import type { SiteSettings } from "~/@types/siteSettings"

const { data: settings } = useNuxtData<SiteSettings>("site-settings")

const footerLinks = computed(() => settings.value?.footerLinks)
</script>

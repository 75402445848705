<template>
  <ul>
    <template
      v-for="({ type, url }, index) in social"
      :key="`social-${type}-${index}`"
    >
      <li v-if="url" class="leading-none">
        <NuxtLink class="inline-flex" :to="url" target="_blank" :title="type">
          <Icon
            v-if="type && icons[type]"
            :name="icons[type]"
            size="1.5em"
            class="text-black hover:text-gray-500"
          />
          <span v-else>{{ type }}</span>
        </NuxtLink>
      </li>
    </template>
  </ul>
</template>

<script setup lang="ts">
import type { SiteSettings } from "~/@types/siteSettings"
import type { SocialTypes } from "~/@types/utils"

const { data: settings } = useNuxtData<SiteSettings>("site-settings")
const social = computed(() => settings.value?.social ?? [])

const icons: Partial<Record<SocialTypes, string>> = {
  threads: "ph:threads-logo",
  instagram: "mdi:instagram",
  linkedin: "mdi:linkedin",
}
</script>


// Generated by dayjs-nuxt-module
import dayjs from 'dayjs'
import updateLocale from 'dayjs/plugin/updateLocale'

import 'dayjs/locale/en'
import relativeTime from 'dayjs/plugin/relativeTime'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import localizedFormat from 'dayjs/plugin/localizedFormat'


dayjs.extend(updateLocale)

dayjs.extend(relativeTime)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(localizedFormat)

dayjs.tz.setDefault('America/New_York')

// defaultLocale: ["en",{"relativeTime":{"future":"in %s","past":"%s ago","s":"1s","m":"1m","mm":"%dm","h":"1h","hh":"%dh","d":"1d","dd":"%dd","M":"1mo","MM":"%dmo","y":"1y","yy":"%dy"}}]


dayjs.updateLocale("en",{"relativeTime":{"future":"in %s","past":"%s ago","s":"1s","m":"1m","mm":"%dm","h":"1h","hh":"%dh","d":"1d","dd":"%dd","M":"1mo","MM":"%dmo","y":"1y","yy":"%dy"}})
dayjs.locale('en')


export default dayjs

<template>
  <div>
    <Component
      :is="component(menu._type)"
      v-for="(menu, index) in items"
      :key="`nav-item-${index}`"
      v-bind="{
        ...{ menu },
        ...componentProps(menu._type),
      }"
    />
  </div>
</template>

<script setup lang="ts">
import type { Menu, MenuMapping } from "~/@types/siteSettings"

const props = defineProps<{
  items: Menu[]
  mapping: MenuMapping
}>()

const { items, mapping } = toRefs(props)

const component = (type: keyof MenuMapping) => mapping.value[type].component
const componentProps = (type: keyof MenuMapping) => mapping.value[type].props
</script>

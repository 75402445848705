<template>
  <button
    ref="target"
    class="flex items-center gap-1 py-5 uppercase transition-opacity duration-300"
    :class="[{ 'opacity-60': hovered }]"
    :aria-expanded="hovered"
    @mouseover="show()"
    @mouseout="hide()"
  >
    <span class="font-bold">{{ title }}</span>
    <Icon name="ic:outline-keyboard-arrow-down" />
  </button>
  <TransitionFade v-if="items?.length">
    <div
      v-if="hovered"
      ref="dropdown"
      :style="[floatingStyles]"
      @mouseover="show()"
      @mouseout="hide()"
    >
      <div
        class="border-primary z-50 flex flex-col justify-center gap-4 border border-t-2 border-t-black bg-white px-5 py-6 text-center"
      >
        <MenuItem
          v-for="({ menu: item, label }, index) in items"
          :key="`nav-item-${item._type}-${index}`"
          :class="$attrs.class"
          class="w-full min-w-44"
          :item="item"
          :label="label"
        />
      </div>
    </div>
  </TransitionFade>
</template>

<script lang="ts" setup>
import { useFloating, autoUpdate, shift } from "@floating-ui/vue"
import type { MenuDropdown } from "~/@types/siteSettings"

const props = defineProps<{
  menu: MenuDropdown
}>()

defineOptions({
  inheritAttrs: false,
})

const { menu } = toRefs(props)
const target = ref<HTMLElement>()
const dropdown = ref<HTMLElement>()
const hovered = ref<boolean>(false)

const { start, stop } = useTimeoutFn(
  () => {
    hovered.value = false
  },
  100,
  {
    immediate: false,
  },
)

const title = computed(() => menu.value.label)
const items = computed(() => menu.value.dropdowns ?? [])

const { floatingStyles } = useFloating(target, dropdown, {
  middleware: [
    shift({
      padding: 16,
    }),
  ],
  whileElementsMounted: autoUpdate,
})

const show = () => {
  stop()

  hovered.value = true
}

const hide = () => {
  start()
}
</script>

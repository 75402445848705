<template>
  <ClientOnly>
    <Teleport to="body">
      <Transition
        enter-from-class="-translate-x-full"
        enter-active-class="transition duration-300"
        leave-to-class="-translate-x-full"
        leave-active-class="transition duration-300"
      >
        <div
          v-show="opened"
          class="fixed left-0 top-0 z-50 flex h-full w-9/12 flex-col bg-white p-4"
        >
          <header class="bg-white text-right">
            <button class="hover:opacity-60" @click="toggleMenu(false)">
              <Icon name="ant-design:close-outlined" class="text-2xl" />
            </button>
          </header>
          <div ref="el" class="grow overflow-auto pb-8 pt-4">
            <NavSide class="grid gap-4" />
          </div>
          <footer class="mt-auto">
            <SiteSocialList class="mb-4 flex items-center gap-5" />
            <SiteFooterLinks class="flex gap-5 text-xs font-bold uppercase" />
          </footer>
        </div>
      </Transition>
      <Transition
        enter-from-class="opacity-0"
        enter-active-class="transition duration-300"
        leave-to-class="opacity-0"
        leave-active-class="transition duration-300"
      >
        <div
          v-show="opened"
          class="fixed inset-0 z-40 bg-black bg-opacity-65"
          @click="toggleMenu(false)"
        />
      </Transition>
    </Teleport>
  </ClientOnly>
</template>

<script setup lang="ts">
const props = defineProps<{
  opened: boolean
  toggleMenu: (value?: boolean | undefined) => boolean
}>()

const el = ref<HTMLElement>()
const { opened, toggleMenu } = toRefs(props)
const isLocked = useScrollLock(window)

watchEffect(() => {
  isLocked.value = opened.value
})

onMounted(() => {
  nextTick(() => {
    el.value?.addEventListener("click", (event: Event) => {
      const target = event.target as Node

      if (target.nodeName.toLowerCase() === "a") {
        toggleMenu.value(false)
      }
    })
  })
})
</script>

<template>
  <div class="container">
    <header class="flex items-center justify-center py-3">
      <div class="flex-1">
        <NuxtLink
          v-if="upperLink"
          class="text-sm font-bold uppercase"
          :to="upperLink.navMenuLeftUrl"
        >
          {{ upperLink.label }}
        </NuxtLink>
      </div>
      <div class="w-40">
        <NuxtLink to="/" title="CALIBRE">
          <img class="w-full" src="~/assets/logo.svg" alt="CALIBRE" />
        </NuxtLink>
      </div>
      <div class="flex flex-1 items-center">
        <button class="ml-auto inline-flex sm:hidden" @click="toggleMenu(true)">
          <Icon name="ci:hamburger" class="text-3xl hover:opacity-60" />
        </button>

        <SiteSocialList class="ml-auto hidden items-center gap-5 sm:flex" />
      </div>
    </header>

    <NavMain
      class="mx-auto flex max-w-2xl items-center justify-between px-4 max-sm:hidden"
    />

    <SiteSideMenu :opened="menuOpened" :toggle-menu="toggleMenu" />
  </div>
</template>

<script setup lang="ts">
import type { SiteSettings } from "~/@types/siteSettings"

const { data: settings } = useNuxtData<SiteSettings>("site-settings")

const [menuOpened, toggleMenu] = useToggle(false)

const upperLink = settings.value?.navMenuLeft
</script>

import { gsap } from "gsap"
import { ScrollToPlugin, ScrollTrigger } from "gsap/all"

export default () => {
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin)

  return {
    gsap,
    ScrollTrigger,
  }
}

<template>
  <div ref="target" class="bg-white">
    <div class="mx-auto max-w-2xl p-4">
      <form class="flex gap-2 text-xs" @submit.prevent="doSearch()">
        <div
          class="flex flex-1 items-center rounded border border-grayBorder has-[:focus]:border-black has-[:focus]:ring-black"
        >
          <input
            v-model="search"
            class="h-full w-full bg-transparent px-3 py-2 text-xs outline-none focus:max-sm:text-base"
            type="search"
            :placeholder="placeholder"
            maxlength="30"
          />
          <button
            type="button"
            class="flex px-3 py-1 text-xl text-gray-500 hover:text-black"
            aria-label="Toggle Search"
            @click="toggleDropdown()"
          >
            <Icon name="ion:ios-settings-strong" />
          </button>
        </div>
        <button class="btn font-bold" type="submit" :disabled="!search">
          Search
        </button>
      </form>
    </div>

    <div
      v-if="activeDropdown"
      ref="dropdown"
      :style="[floatingStyles]"
      class="z-10 w-full"
    >
      <div class="mx-auto w-full max-w-2xl px-4 text-xs">
        <div
          ref="dropdownInner"
          class="w-full rounded-b-md border border-t-0 bg-white px-4 py-2 shadow-sm"
        >
          <p class="mb-1 text-sm font-bold uppercase">Search type</p>
          <div class="flex gap-4">
            <template
              v-for="({ id, value, label }, key) in options"
              :key="`search-option-${id}`"
            >
              <div class="flex items-center gap-2">
                <input
                  :id="id"
                  v-model="searchContext"
                  type="radio"
                  class="size-3 appearance-none rounded-full border border-gray-500 checked:border-4 checked:border-black"
                  :value="value"
                  @click="updateActiveConfig(options[key])"
                />
                <label :for="id" class="py-2">{{ label }}</label>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useFloating, autoUpdate } from "@floating-ui/vue"
import { DEFAULT_SEARCH_TYPE } from "~/config/defaults"

const options: Record<string, Option> = {
  opportunities: {
    id: "search-opportunities",
    value: "opportunities",
    label: "Jobs",
    placeholder: "Search jobs by keyword",
  },
  editorial: {
    id: "search-editorial",
    value: "editorial",
    label: "Editorial",
    placeholder: "Search editorial by keyword",
  },
  brands: {
    id: "search-brands",
    value: "brands",
    label: "Brands",
    placeholder: "Search brands by keyword",
  },
}

const route = useRoute()
const search = defineModel<string>()
const defaultOption = () => options[DEFAULT_SEARCH_TYPE]

const emits = defineEmits<{
  submit: []
}>()

const searchContext = ref<string>(
  (route.query.type as string) ?? DEFAULT_SEARCH_TYPE,
)
const target = ref<HTMLElement>()
const dropdown = ref<HTMLElement>()
const dropdownInner = ref<HTMLElement>()
const activeOption = ref<Option>(defaultOption())

const [activeDropdown, toggleDropdown] = useToggle(false)

type Option = {
  id: string
  value: string
  label: string
  placeholder: string
}

const { floatingStyles } = useFloating(target, dropdown, {
  whileElementsMounted: autoUpdate,
})

const placeholder = computed(() => activeOption.value.placeholder)

const updateActiveConfig = (option: Option) => {
  activeOption.value = option
}

const doSearch = async () => {
  // don't go further if search term is empty
  if (!search.value) return

  emits("submit")

  activeDropdown.value = false

  await navigateTo({
    path: "/search",
    query: {
      s: search.value,
      type: activeOption.value.value,
    },
  })
}

onClickOutside(dropdownInner, () => (activeDropdown.value = false))

watchEffect(() => {
  search.value = route.query.s as string

  const option =
    route.query.type && options[route.query.type as string]
      ? options[route.query.type as string]
      : defaultOption()

  updateActiveConfig(option)
})
</script>

import { default as _91slug_93127jyH7nILMeta } from "/opt/build/repo/nuxt/pages/brands/[slug].vue?macro=true";
import { default as indexrb0Fe6T8foMeta } from "/opt/build/repo/nuxt/pages/brands/index.vue?macro=true";
import { default as _91slug_93UwqW9QosybMeta } from "/opt/build/repo/nuxt/pages/careers/[slug].vue?macro=true";
import { default as _91slug_93X4dyYcSdkUMeta } from "/opt/build/repo/nuxt/pages/cities/[slug].vue?macro=true";
import { default as _91slug_93Hup2OxOxWUMeta } from "/opt/build/repo/nuxt/pages/countries/[slug].vue?macro=true";
import { default as _91slug_93cZTfNAE2XhMeta } from "/opt/build/repo/nuxt/pages/editorial/[section]/[slug].vue?macro=true";
import { default as indexkOktxagW7FMeta } from "/opt/build/repo/nuxt/pages/editorial/[section]/index.vue?macro=true";
import { default as indexHyBUpxpn8lMeta } from "/opt/build/repo/nuxt/pages/editorial/index.vue?macro=true";
import { default as previewe30XaKeqDBMeta } from "/opt/build/repo/nuxt/pages/editorial/preview.vue?macro=true";
import { default as indext1gLk5rFm2Meta } from "/opt/build/repo/nuxt/pages/index.vue?macro=true";
import { default as _91slug_938gPQkdQZLzMeta } from "/opt/build/repo/nuxt/pages/industries/[slug].vue?macro=true";
import { default as _91slug_93DMstsvDPE6Meta } from "/opt/build/repo/nuxt/pages/opportunities/[slug].vue?macro=true";
import { default as previewf4pC2oVL4HMeta } from "/opt/build/repo/nuxt/pages/opportunities/preview.vue?macro=true";
import { default as _91slug_935sckFszOxEMeta } from "/opt/build/repo/nuxt/pages/page/[slug].vue?macro=true";
import { default as previewQdH3x0UrXcMeta } from "/opt/build/repo/nuxt/pages/page/preview.vue?macro=true";
import { default as searchA3I5xxmu3bMeta } from "/opt/build/repo/nuxt/pages/search.vue?macro=true";
import { default as _91slug_936bOglugvZxMeta } from "/opt/build/repo/nuxt/pages/site/[slug].vue?macro=true";
import { default as _91slug_93UXv41poYhfMeta } from "/opt/build/repo/nuxt/pages/types/[slug].vue?macro=true";
export default [
  {
    name: "brands-slug",
    path: "/brands/:slug()",
    component: () => import("/opt/build/repo/nuxt/pages/brands/[slug].vue").then(m => m.default || m)
  },
  {
    name: "brands",
    path: "/brands",
    component: () => import("/opt/build/repo/nuxt/pages/brands/index.vue").then(m => m.default || m)
  },
  {
    name: "careers-slug",
    path: "/careers/:slug()",
    component: () => import("/opt/build/repo/nuxt/pages/careers/[slug].vue").then(m => m.default || m)
  },
  {
    name: "cities-slug",
    path: "/cities/:slug()",
    component: () => import("/opt/build/repo/nuxt/pages/cities/[slug].vue").then(m => m.default || m)
  },
  {
    name: "countries-slug",
    path: "/countries/:slug()",
    component: () => import("/opt/build/repo/nuxt/pages/countries/[slug].vue").then(m => m.default || m)
  },
  {
    name: "editorial-section-slug",
    path: "/editorial/:section()/:slug()",
    component: () => import("/opt/build/repo/nuxt/pages/editorial/[section]/[slug].vue").then(m => m.default || m)
  },
  {
    name: "editorial-section",
    path: "/editorial/:section()",
    component: () => import("/opt/build/repo/nuxt/pages/editorial/[section]/index.vue").then(m => m.default || m)
  },
  {
    name: "editorial",
    path: "/editorial",
    component: () => import("/opt/build/repo/nuxt/pages/editorial/index.vue").then(m => m.default || m)
  },
  {
    name: "editorial-preview",
    path: "/editorial/preview",
    meta: previewe30XaKeqDBMeta || {},
    component: () => import("/opt/build/repo/nuxt/pages/editorial/preview.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/opt/build/repo/nuxt/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "industries-slug",
    path: "/industries/:slug()",
    component: () => import("/opt/build/repo/nuxt/pages/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: "opportunities-slug",
    path: "/opportunities/:slug()",
    component: () => import("/opt/build/repo/nuxt/pages/opportunities/[slug].vue").then(m => m.default || m)
  },
  {
    name: "opportunities-preview",
    path: "/opportunities/preview",
    meta: previewf4pC2oVL4HMeta || {},
    component: () => import("/opt/build/repo/nuxt/pages/opportunities/preview.vue").then(m => m.default || m)
  },
  {
    name: "page-slug",
    path: "/page/:slug()",
    component: () => import("/opt/build/repo/nuxt/pages/page/[slug].vue").then(m => m.default || m)
  },
  {
    name: "page-preview",
    path: "/page/preview",
    meta: previewQdH3x0UrXcMeta || {},
    component: () => import("/opt/build/repo/nuxt/pages/page/preview.vue").then(m => m.default || m)
  },
  {
    name: "search",
    path: "/search",
    meta: searchA3I5xxmu3bMeta || {},
    component: () => import("/opt/build/repo/nuxt/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "site-slug",
    path: "/site/:slug()",
    component: () => import("/opt/build/repo/nuxt/pages/site/[slug].vue").then(m => m.default || m)
  },
  {
    name: "types-slug",
    path: "/types/:slug()",
    component: () => import("/opt/build/repo/nuxt/pages/types/[slug].vue").then(m => m.default || m)
  }
]
<template>
  <MenuBuilder :items="items" :mapping="menuItemMapping" />
</template>

<script setup lang="ts">
import type { MenuMapping, SiteSettings } from "~/@types/siteSettings"
import { MenuAccordion, MenuUri } from "#components"

const { data: settings } = useNuxtData<SiteSettings>("site-settings")

const items = computed(() =>
  settings.value?.navBar?.filter((n) => n.location.side),
)

const menuItemMapping: MenuMapping = {
  menuReference: {
    component: MenuAccordion,
  },
  menuUri: {
    component: MenuUri,
    props: {
      class:
        "font-bold uppercase transition-opacity duration-300 hover:opacity-60",
    },
  },
}
</script>

<template>
  <MenuBuilder :items="items" :mapping="menuItemMapping" />
</template>

<script setup lang="ts">
import type { SiteSettings, MenuMapping } from "~/@types/siteSettings"
import { MenuDropdown, MenuUri } from "#components"

const { data: settings } = useNuxtData<SiteSettings>("site-settings")

const items = computed(() =>
  settings.value?.navBar?.filter((n) => n.location.nav),
)

const menuItemMapping: MenuMapping = {
  menuReference: {
    component: MenuDropdown,
    props: {
      class: "hover:opacity-60",
    },
  },
  menuUri: {
    component: MenuUri,
    props: {
      class:
        "py-5 font-bold uppercase transition-opacity duration-300 hover:opacity-60",
    },
  },
}
</script>
